import { isNullOrUndefinedArgs } from '@pochico/shared';

import {
  CollectionReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';
import {
  BulkSpotAddHistory,
  BulkSpotAddHistoryFromFS,
} from '../../firebase/types';

export const getBulkSpotAddHistoryRef = (
  providerId: string
): CollectionReference<BulkSpotAddHistory> => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    CONSTANTS.COLLECTION.BULK_SPOT_ADD_HISTORIES
  ).withConverter({
    toFirestore(doc: BulkSpotAddHistory): BulkSpotAddHistory {
      return {
        ...doc,
      };
    },
    fromFirestore(
      snap: QueryDocumentSnapshot<BulkSpotAddHistoryFromFS>
    ): BulkSpotAddHistory {
      const nullableDoc = snap.exists() ? snap.data() : undefined;
      if (
        !nullableDoc ||
        isNullOrUndefinedArgs(
          nullableDoc.id,
          nullableDoc.providerAccountId,
          nullableDoc.createTime,
          nullableDoc.bookingMenuId,
          nullableDoc.excludeDays,
          nullableDoc.slots
        )
      ) {
        throw new Error(
          'invalid args from firestore spot: ' + JSON.stringify(nullableDoc)
        );
      }
      return {
        ...nullableDoc,
        createTime: nullableDoc.createTime.toDate(),
      } as BulkSpotAddHistory;
    },
  });
};

export const fetchBulkSpotAddHistories = async (
  providerAccountId: string,
  take?: number
) => {
  const ref = getBulkSpotAddHistoryRef(providerAccountId);
  return getDocs(
    query(
      ref,
      orderBy('createTime', 'desc'),
      limit(take ?? CONSTANTS.DEFAULT_BULK_SPOT_ADD_HISTORY_NUM)
    )
  ).then((snap) => {
    return {
      data: (snap as QuerySnapshot<BulkSpotAddHistory>).docs.map((doc) =>
        doc.data()
      ),
      total: snap.size,
    };
  });
};
