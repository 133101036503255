import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, parseTimeString } from '@pochico/shared';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { BulkSpotAddHistory } from '../../../firebase/types';
import { DayOfWeek, dayOfWeeks } from '../../../helpers/dayOfWeeks';
import { BulkAddSpotHistorySelect } from './BulkAddSpotHistorySelect';
import { BulkAddSpotsInput, SpotSlot } from './types';

type Props = {
  bulkSpotAddHistoryList: BulkSpotAddHistory[];
  bookingMenu: BookingMenu | undefined;
  onSelectHistory: (history: BulkSpotAddHistory) => void;
};
export const SpotSlotsInput: React.FC<Props> = ({
  bulkSpotAddHistoryList,
  bookingMenu,
  onSelectHistory,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BulkAddSpotsInput>();

  const value = useWatch({ name: 'slots', control });
  const slotInputHeight = React.useMemo(() => {
    return (
      Math.max(
        dayOfWeeks.reduce((max, dayOfWeek) => {
          return Math.max(max, value[dayOfWeek.id].length);
        }, 0),
        1
      ) *
        dayOfWeekSlotStyle.height +
      dayOfWeekSlotStyle.minHeight +
      8
    );
  }, [value]);
  const errorMessage = React.useMemo(() => {
    if (errors.slots?.message) {
      return errors.slots.message;
    } else {
      const err = dayOfWeeks.find((dayOfWeek) => {
        const l = errors?.slots?.[dayOfWeek.id]?.length;
        return l && l > 0;
      });
      if (err) {
        return `入力内容に不備があります`;
      } else {
        return undefined;
      }
    }
  }, [errors]);

  return (
    <FormControl isRequired isInvalid={Boolean(errorMessage)}>
      <VStack
        alignItems={'flex-start'}
        w={'full'}
        justifyContent={'flex-start'}
      >
        <FormLabel fontSize={'16px'} fontWeight={'bold'}>
          ④ 曜日別に予約可能な枠を設定してください
        </FormLabel>
        <Text color={'gray.600'} fontSize={'xs'}>
          予約枠の「開始時間」とその時間の「枠数」を入力してください。
        </Text>

        <Box
          w={'full'}
          maxW={{ base: 'full', md: `570px` }}
          borderWidth={'1px'}
          borderColor={'gray.200'}
          p={'16px'}
          bg={'gray.50'}
          borderRadius={'4px'}
        >
          <BulkAddSpotHistorySelect
            histories={bulkSpotAddHistoryList}
            bookingMenu={bookingMenu}
            onSelect={onSelectHistory}
          />
        </Box>
        <Box
          w={{ base: '100vw', md: 'full' }}
          overflowX={'auto'}
          paddingRight={{ base: '32px', md: undefined }}
          // marginLeft={{ base: '-32px', md: 0 }}
        >
          <HStack
            borderRightColor={'gray.300'}
            borderRightWidth={'1px'}
            overflowX={'auto'}
            w={'fit-content'}
            direction={'column'}
            alignItems={'flex-start'}
            backgroundColor={'white'}
            borderColor={'gray.500'}
            spacing={0}
            h={'max-content'}
            borderWidth={'1px'}
          >
            {dayOfWeeks.map((dayOfWeek, i) => (
              <Box
                key={dayOfWeek.id}
                height={`${slotInputHeight}px`}
                minHeight={`${dayOfWeekSlotStyle.minHeight}px`}
                borderRightColor={
                  i === dayOfWeeks.length - 1 ? 'transparent' : 'gray.500'
                }
                borderRightWidth={i === dayOfWeeks.length - 1 ? 0 : '1px'}
                pb={'8px'}
              >
                <DayOfWeekStartTimeInput dayOfWeek={dayOfWeek} />
              </Box>
            ))}
          </HStack>
        </Box>

        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </VStack>
    </FormControl>
  );
};

const dayOfWeekSlotStyle = {
  minHeight: 80,
  height: 40,
  width: 170,
  heightPx: '80px',
  widthPx: '170px',
};

const DayOfWeekStartTimeInput: React.FC<{ dayOfWeek: DayOfWeek }> = ({
  dayOfWeek,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<BulkAddSpotsInput>();
  const watch = useWatch({ name: `slots.${dayOfWeek.id}`, control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `slots.${dayOfWeek.id}`,
  });

  const onClickAdd = React.useCallback(() => {
    // 追加ボタンを押すと差分から次の値を計算して補完しておく
    const nextValue = getNextValues(watch);
    append(nextValue as any);
  }, [append, watch]);

  return (
    <VStack
      p={0}
      margin={0}
      alignItems={'flex-start'}
      spacing={0}
      w={dayOfWeekSlotStyle.width}
      h={'full'}
    >
      <Text
        fontSize={'md'}
        w={'full'}
        bgColor={'gray.200'}
        textAlign={'center'}
        fontWeight={'bold'}
        paddingX={'8px'}
        paddingY={'4px'}
      >
        {dayOfWeek.name}曜日({dayOfWeek.id.toUpperCase()})
      </Text>
      <VStack
        justifyContent={'space-between'}
        // h={'full'}
        w={'full'}
        bgColor={'white'}
        px={'8px'}
        pt={fields.length > 0 ? '8px' : undefined}
        borderRightColor={'gray.500'}
        // borderRightWidth={'1px'}
      >
        <VStack w={'full'}>
          {fields.length > 0 ? (
            fields.map((field, index) => {
              const error = errors.slots?.[dayOfWeek.id]?.[index];
              return (
                <HStack
                  key={`${field.id}`}
                  spacing={{ base: '4px', md: '4px' }}
                  w={'full'}
                >
                  <FormControl isRequired isInvalid={!!error?.startTime}>
                    <InputGroup w={'full'}>
                      {/* <InputLeftElement pointerEvents="none">
                    <AccessTime />
                  </InputLeftElement> */}
                      <Input
                        type="time"
                        backgroundColor={'white'}
                        size={'sm'}
                        w={'72px'}
                        px={'4px'}
                        // px={'0px'}
                        sx={{
                          '&::-webkit-calendar-picker-indicator': {
                            display: 'none',
                          },
                        }}
                        placeholder={'10:00'}
                        {...register(
                          `slots.${dayOfWeek.id}.${index}.startTime`,
                          {
                            required: '未入力です',
                            validate: timeStringValidation,
                          }
                        )}
                      />
                    </InputGroup>
                    {/* <FormErrorMessage>{error?.message}</FormErrorMessage> */}
                  </FormControl>
                  <FormControl isInvalid={!!error?.maxBookings?.message}>
                    <InputGroup w={'full'}>
                      <Input
                        type="number"
                        w={'56px'}
                        size={'sm'}
                        backgroundColor={'white'}
                        placeholder={'枠数'}
                        {...register(
                          `slots.${dayOfWeek.id}.${index}.maxBookings`,
                          {
                            min: 1,
                            required: '未入力です',
                            valueAsNumber: true,
                            validate: maxBookingsValidation,
                          }
                        )}
                      />
                      {/* <InputRightAddon>枠</InputRightAddon> */}
                    </InputGroup>
                    {/* <FormErrorMessage>
                      {error?.maxBookings?.message}
                    </FormErrorMessage> */}
                  </FormControl>
                  <Button
                    color={'red'}
                    size={'xs'}
                    p={0}
                    variant={'transparent-clickable'}
                    onClick={() => remove(index)}
                  >
                    ×
                  </Button>
                </HStack>
              );
            })
          ) : (
            <VStack
              w={'full'}
              h={'full'}
              justifyContent={'center'}
              color={'gray.500'}
            >
              {/* <Text>未設定</Text> */}
            </VStack>
          )}
        </VStack>
        <Button
          variant={'soft-fill'}
          w={'full'}
          colorScheme={'blue'}
          size={'sm'}
          onClick={onClickAdd}
          marginTop={'8px'}
          // marginBottom={'8px'}
          fontSize={'sm'}
          borderRadius={'6px'}
          h={'32px'}
          py={'4px'}
        >
          ＋ 予約枠を追加
        </Button>
      </VStack>
    </VStack>
  );
};

// 一つ前と二つ前の差分から次に入力される値を計算する
const getNextValues = (fields: SpotSlot[]): Partial<SpotSlot> => {
  const previousOne = fields[fields.length - 1];

  if (!previousOne) {
    return { startTime: '09:00', maxBookings: undefined };
  } else {
    const ppOne = fields[fields.length - 2];
    // もう一つ前との差分を追加する
    if (ppOne) {
      const ppTime = parseTimeString(ppOne.startTime);
      const pTime = parseTimeString(previousOne.startTime);
      if (
        ppTime &&
        pTime &&
        (ppTime.hour < pTime.hour ||
          (ppTime.hour === pTime.hour && ppTime.minute < pTime.minute))
      ) {
        return {
          startTime: addTime(
            previousOne.startTime,
            (pTime.hour - ppTime.hour) * 60 + (pTime.minute - ppTime.minute)
          ),
          maxBookings: previousOne.maxBookings,
        };
      }
    }
    return {
      startTime: addTime(previousOne.startTime, 30),
      maxBookings: previousOne.maxBookings,
    };
  }
};

// 時間を足す関数
const addTime = (timeString: string, minutesToAdd: number): string => {
  const parsed = parseTimeString(timeString);
  if (!parsed) {
    return '09:00';
  }
  const { hour, minute } = parsed;

  let _hour, _minute;
  if (60 <= minute + minutesToAdd) {
    const hourToAdd = Math.floor((minute + minutesToAdd) / 60);
    _hour = hour + hourToAdd;
    _minute = minute + minutesToAdd - 60 * hourToAdd;
  } else {
    _hour = hour;
    _minute = minute + minutesToAdd;
  }

  if (_hour < 0) {
    _hour = 0;
  } else if (_hour >= 24) {
    _hour = 23;
  }
  // console.log({ where: 'addTime', parsed, minutesToAdd, _hour, _minute });

  const pad = (n: number) => String(n).padStart(2, '0');
  return `${pad(_hour)}:${pad(_minute)}`;
};

const timeStringValidation = (value: string) => {
  if (!value) {
    return '必須項目です';
  }
  const parsed = parseTimeString(value);
  if (!parsed) {
    return 'フォーマットが不正です';
  }
  return;
};

const maxBookingsValidation = (value: any) => {
  if (!value) {
    return '必須です';
  }
  if (typeof value !== 'number') {
    return `数値を入力してください`;
  }
  if (value <= 0 || value > 1000) {
    return '予約枠数は1から1000の範囲で入力して下さい';
  }
  return undefined;
};
