// declare var GLOBAL_CONFIG: any;

import { MACRO } from '@pochico/shared';

const CONSTANTS = {
  // Firestore
  COLLECTION: {
    // Provider Accountに紐付くサブコレクションズ
    PROVIDER_ACCOUNTS: 'providerAccounts',
    SUB_LINE_USERS: '_lineUsers',
    SUB_BOOKINGS: '_bookings',
    SUB_BOOKING_MENU: '_bookingMenu',
    SUB_SPOTS: '_spots',
    SUB_BOOKING_FORMS: '_bookingForms',
    SUB_SHARED_BOOKING_FORMS: '_sharedBookingForms',
    SUB_SURVEYS: '_surveys',
    BULK_SPOT_ADD_HISTORIES: '_bulkSpotAddHistories',

    // Management関連
    ATTACH_HISTORIES: 'attachHistories',

    // staff
    PROVIDER_STAFFS: 'providerStaffs',

    // アプリケーション自体のデータ関連
    APPLICATION_CONFIG: 'applicationConfig',
  },
  SPOT_START_TIME_OPTIONS: [
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
  ],
  BOOKING_MENU_STATUS_OPTIONS: ['ON', 'OFF'],
  DEFAULT_LOCATION_KEY: '_default',
  DEFAULT_BOOKING_MENU_ID: '_default',
  DEFAULT_BOOKING_MENU_NAME: 'デフォルトメニュー',
  DEFAULT_BOOKING_MENU_PRIORITY: 1, // z-indexの考え方
  MAX_BOOKING_MENU_ACTIVE_NUMBERS: 10,
  MAX_BOOKING_MENU_DRAFT_NUMBERS: 20,
  DEFAULT_BOOKING_LIMIT_DAY_BEFORE: 7, // 1week
  DEFAULT_BOOKING_LIMIT_TIME: undefined,
  DEFAULT_BULK_SPOT_ADD_HISTORY_NUM: 10,
  BOOKING_SUCCESS_SUFFIX: `予約日時：${MACRO.fullDateJa}`,
  DEFAULT_BOOKING_SUCCESS: `予約が完了しました。\n予約日時：${MACRO.fullDateJa}`,
  MAX_BULK_SPOT_PERIOD_DAYS: 31 * 3, // 3 month
  START_BOOKING_CALL_TEXT_FOR_ONBOARDING:
    '[予約する] ※このまま送信してください',
  PAGINATION_PER_PAGE_DEFAULT: 10,
  PAGINATION_MAX_LIMIT: 29999, // +1してFIRESTORE_MAX_LIMITの倍数になるようにしてある
  PLAN: {
    STANDARD: {
      NAME: 'スタンダードプラン',
      PRICE: (3000 * 1.1).toFixed(0),
    },
  },
  MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_PC: 30,
  MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_SP: 10,
  FIRESTORE_MAX_LIMIT: 10000, // firestoreの制限で1ページあたり10000件まで
};

export default CONSTANTS;
