import { ProviderAccount } from '@pochico/shared';
import { BulkAddSpotsInput } from './types';

const localStorageKey = (providerAccount: ProviderAccount | undefined) => {
  // providerAccountのnull checkが面倒だしクリティカルでも無いので雑に対応
  if (providerAccount) {
    return `bulk-add-spots-input_${providerAccount.id}`;
  } else {
    return `bulk-add-spots-input`;
  }
};

export const writeToLocalStorage = (
  providerAccount: ProviderAccount | undefined,
  input: BulkAddSpotsInput | undefined
) => {
  const key = localStorageKey(providerAccount);
  if (input) {
    localStorage.setItem(key, JSON.stringify(input));
  } else {
    localStorage.removeItem(key);
  }
};

export const readFromLocalStorage = (
  providerAccount: ProviderAccount | undefined
): BulkAddSpotsInput | undefined => {
  if (!providerAccount) {
    return undefined;
  }
  const key = localStorageKey(providerAccount);
  const storedInput = localStorage.getItem(key);
  if (!storedInput) {
    // 互換性対応
    const input = localStorage.getItem(localStorageKey(undefined));
    console.log({ storedInput, input });
    const result = input ? JSON.parse(input) : undefined;
    if (result) {
      // データ移行して元のやつは消しておく
      writeToLocalStorage(providerAccount, result);
      writeToLocalStorage(undefined, undefined);
      console.log(`localStorage contents migrated to ${key}`);
    }
    return result;
  }
  return JSON.parse(storedInput);
};
