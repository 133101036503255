import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Spacer,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { AttachErrorCode, sleep } from '@pochico/shared';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { MdContentCopy, MdOutlineLightbulb } from 'react-icons/md';
import { FixedRight } from '../../components/ui/Fixed';
import { Layout } from '../../components/ui/Layout';
import { Loading } from '../../components/ui/Loading';
import { PageTitle } from '../../components/ui/PageTitle';
import { RightArrowWhite } from '../../components/ui/RightArrow';
import { WebLink } from '../../components/ui/WebLink';
import { useAuthState } from '../../context/providerAccount';
import { getBotApi, postBotApi } from '../../dataStore/bot';
import { Path } from '../../routers/Path';
import { Number1, Number2, Number3, Number4, StepTitle } from './components';

type BookingLiffIdRegistrationParam = {
  liffId: string;
};
export const BookingLiffIdRegistration = () => {
  const navigate = useNavigate();
  const { refetch, providerAccount, firebaseUser } = useAuthState();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = useForm<BookingLiffIdRegistrationParam>({
    mode: 'onChange',
  });
  const [submitting, setSubmitting] = React.useState(false);

  const fetchLiffAppUrl = React.useCallback(() => {
    if (!providerAccount || !firebaseUser) {
      return;
    }
    return getBotApi<{ liffAppUrl: string }>(
      `/provider-accounts/${providerAccount.id}/liffAppUrl`,
      firebaseUser
    ).then((res) => {
      if (res.ok) {
        return res.body.liffAppUrl;
      } else {
        const err = `Failed to fetch liffAppUrl: ${res.error}`;
        console.error(err);
        return Promise.reject(err);
      }
    });
  }, [firebaseUser, providerAccount]);
  const fetchLiffAppUrlQuery = useQuery({
    queryKey: ['liffAppUrl', providerAccount?.id],
    queryFn: fetchLiffAppUrl,
  });

  const onSubmit = React.useCallback(
    async (param: BookingLiffIdRegistrationParam) => {
      if (!firebaseUser || !providerAccount) {
        return;
      }
      setSubmitting(true);
      postBotApi<{ ok: true } | { ok: false; errorCode: AttachErrorCode }>(
        '/registration/liff',
        firebaseUser,
        {
          ...param,
          providerAccountId: providerAccount?.id || undefined,
        }
      )
        .then(async (response) => {
          if (response.ok) {
            toast({
              title: 'LIFFアプリが利用可能になりました',
              status: 'success',
            });
            await Promise.all([sleep(3000), refetch()]);
            navigate(Path.configuration);
          } else {
            toast({
              title: `LIFFアプリの有効化に失敗しました。エラー: ${response.error}`,
              status: 'error',
            });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [firebaseUser, navigate, providerAccount, refetch, toast]
  );

  if (!providerAccount || fetchLiffAppUrlQuery.isLoading) {
    return <Loading />;
  } else if (!fetchLiffAppUrlQuery.data) {
    return (
      <Layout pageTitle={'LIFF ID登録'} hasBackButton={Path.configuration}>
        <Text>{String(fetchLiffAppUrlQuery.error)}</Text>
        <Button onClick={() => navigate(-1)}>戻る</Button>
      </Layout>
    );
  }
  const liffAppUrl = fetchLiffAppUrlQuery.data;

  return (
    <Layout pageTitle={'LIFF ID登録'} hasBackButton={Path.configuration}>
      <VStack
        w={'full'}
        spacing={'16px'}
        alignItems={'flex-start'}
        maxW={{ base: 'full', md: '900px' }}
      >
        <Text whiteSpace={'pre-wrap'}>
          ポチコ用に作成したLIFFアプリのIDを入力してください。
        </Text>
        <Spacer mt={8} />
        <HStack
          borderRadius={'xl'}
          borderWidth={'1px'}
          borderColor={'gray.500'}
          bgColor={'gray.50'}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={'16px'}
          paddingY={'32px'}
        >
          <Icon as={MdOutlineLightbulb} color={'yellow.500'} />
          <Text>
            LIFFアプリの作成方法およびLIFFアプリIDの取得方法については、
            <WebLink
              href="https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1"
              target="_blank"
              rel="noopener noreferrer"
              color={'black'}
            >
              こちらのページ
            </WebLink>
            をご参照ください。
          </Text>
        </HStack>

        {/* <Instruction /> */}
        <VStack alignItems={'flex-start'} w={'full'}>
          <Box>
            かんたん予約ポチコ用のLIFFアプリに指定していだたくエンドポイントURLはこちらです。
          </Box>
          <HStack w={'full'}>
            <Box
              color={'red'}
              fontWeight={'bold'}
              fontSize={{ base: 'xs', md: 'md' }}
            >
              https://liff.pochico.app/booking/{providerAccount.id}
              {/* {liffAppUrl} */}
            </Box>
            <IconButton
              icon={<MdContentCopy />}
              borderWidth={'1px'}
              borderColor={'gray.300'}
              aria-label={'コピー'}
              variant={'transparent-clickable'}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://liff.pochico.app/booking/${providerAccount.id}`
                );
                toast({
                  title: 'コピーしました',
                  status: 'success',
                });
              }}
            />
          </HStack>
        </VStack>
        <Spacer mt={8} />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <VStack
            alignItems={'flex-start'}
            spacing={8}
            w={{ base: 'full', md: '400px' }}
          >
            <FormControl isInvalid={!!errors.liffId}>
              <FormLabel>LIFFアプリのID</FormLabel>
              <Input
                maxWidth={'full'}
                {...register('liffId', {
                  required: true,
                  maxLength: {
                    value: 300,
                    message: '最大文字数は300文字です',
                  },
                })}
                name="liffId"
                backgroundColor={'white'}
                type="text"
                placeholder="1234567890-abcdefg"
              />
              {providerAccount?.liff?.bookingPage && (
                <FormHelperText>
                  すでに「{providerAccount.liff.bookingPage}」が設定されています
                </FormHelperText>
              )}
              <FormErrorMessage>{errors.liffId?.message}</FormErrorMessage>
            </FormControl>
            <Button
              type={'submit'}
              variant={'blue-fill'}
              width={'full'}
              borderRadius={'8px'}
              isDisabled={!isValidating && !isValid}
              isLoading={submitting}
            >
              {'LIFFアプリの設定をする'}
              <FixedRight>
                <RightArrowWhite />
              </FixedRight>
            </Button>
          </VStack>
        </form>
      </VStack>
    </Layout>
  );
};

const Instruction = () => {
  return (
    <VStack alignItems={'center'} spacing={8}>
      <PageTitle>LIFFアプリの作成</PageTitle>
      <HStack
        backgroundColor={'gray.100'}
        overflowX={'scroll'}
        w={{ base: 'full', md: '80vw' }}
        alignItems={'flex-start'}
        padding={8}
        spacing={4}
      >
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number1 />
            <StepTitle>
              <WebLink
                href={'https://developers.line.biz/console/'}
                target={'_blank'}
                rel={'noopener'}
              >
                LINEの開発者向け管理画面
              </WebLink>
              からLINEログイン用の新規チャネルを作成する
            </StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_liff_step1-2.png'}
              alt={'LINEログイン用の新規チャネルを作成する'}
              maxHeight={{ base: '60vh', md: '477px' }}
              maxWidth={{ base: '80vw', md: '30vw' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number2 />
            <StepTitle>必要な情報を入力する</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_liff_step3-4.png'}
              alt={'必要な情報を入力する'}
              maxHeight={{ base: '60vh', md: '477px' }}
              maxWidth={{ base: '80vw', md: '30vw' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number3 />
            <StepTitle>LIFFアプリを作成する</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_liff_step5-6.png'}
              alt={'LIFFアプリを作成する'}
              maxHeight={{ base: '60vh', md: '477px' }}
              maxWidth={{ base: '80vw', md: '30vw' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number4 />
            <StepTitle>発行されたLIFFアプリのIDを控える</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_liff_step7.png'}
              alt={'発行されたLIFFアプリのIDを控える'}
              maxHeight={{ base: '60vh', md: '477px' }}
              maxWidth={{ base: '80vw', md: '30vw' }}
            />
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};
