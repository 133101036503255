import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ProviderAccount } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';

import { fetchBulkSpotAddHistories } from '../providers/dataProvider/spotBulkAddHistory';

export const spotBulkAddHistoryQueryKey = createQueryKeys(
  'spotBulkAddHistory',
  {
    list: (providerAccount: ProviderAccount) => [providerAccount.id],
  }
);

export const useFetchSpotBulkAddHistories = ({
  providerAccount,
  take,
}: {
  providerAccount: ProviderAccount;
  take?: number;
}) => {
  return useQuery({
    queryKey: spotBulkAddHistoryQueryKey.list(providerAccount).queryKey,
    queryFn: () => fetchBulkSpotAddHistories(providerAccount.id, take),
  });
};
