import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  dateTimeStringWithWeekDay,
  PaymentDateCalculator,
} from '@pochico/shared';
import dayjs from 'dayjs';
import Spacer from '../components/fragments/Spacer';
import { Link } from '../components/ui/Link';
import { PageTitle } from '../components/ui/PageTitle';
import { RegistrationLayout } from '../components/ui/RegistrationLayout';
import { StripeLinkButton } from '../components/ui/StripeButton';
import { useAuthState } from '../context/providerAccount';
import { useCheckUnpaid } from '../hooks/useCheckUnpaid';
import { useStripeConfirmPayment } from '../hooks/useStripeConfirmPayment';
import { Path } from '../routers/Path';

const Unpaid: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { initialized, providerAccount } = useAuthState();
  const { loading, ...unpaidStatus } = useCheckUnpaid();
  const status = unpaidStatus.status;
  const trialEnd = React.useMemo(() => {
    if (!providerAccount) {
      return false;
    }
    const calc = new PaymentDateCalculator(providerAccount);
    if (calc.trialLastDate.diff(dayjs(), 'month') <= 1) {
      return calc.trialLastDate;
    }
    return undefined;
  }, [providerAccount]);

  const onClickConfirmPaymentMutation = useStripeConfirmPayment();

  React.useEffect(() => {
    if (loading || !initialized) {
      return;
    }

    if (!providerAccount) {
      // alert('ログインしてください');
      // navigate('/login');
      return;
    }
    if (providerAccount?.paymentStatus === 'registered' && status === 'ok') {
      navigate('/');
      return;
    }
    const calc = new PaymentDateCalculator(providerAccount, dayjs());
    if (calc.remainFreeSeconds > 0) {
      navigate('/');
      return;
    }
  }, [initialized, loading, navigate, providerAccount, status]);

  if (!initialized || !providerAccount || loading) {
    return (
      <RegistrationLayout>
        <PageTitle>かんたん予約『ポチコ』の利用継続について</PageTitle>
        <VStack alignItems={'flex-start'}>
          <Box>お支払い情報の確認中です。 status: {status}</Box>
          <Spinner />
        </VStack>
      </RegistrationLayout>
    );
  }
  if (status === 'error') {
    return (
      <RegistrationLayout>
        <PageTitle>かんたん予約アプリ『ポチコ』の利用継続について</PageTitle>
        <VStack alignItems={'center'}>
          <Box>
            エラーが発生しました。ログアウトして再度ログインしてください。
          </Box>
          <Flex direction={'row'} alignItems={'center'}>
            <Button
              m={4}
              colorScheme={'gray'}
              onClick={() => {
                navigate('/logout');
              }}
            >
              ログアウト
            </Button>
          </Flex>
        </VStack>
      </RegistrationLayout>
    );
  }

  return (
    <RegistrationLayout>
      <PageTitle>かんたん予約アプリ『ポチコ』の利用継続について</PageTitle>
      {status === 'ok' ? (
        // 支払い登録がされていないだけの場合
        <VStack
          alignItems={'flex-start'}
          p={{ base: '8px', md: '16px' }}
          w={'full'}
          spacing={'16px'}
        >
          <Box>
            お使いのアカウント ではポチコの支払い登録がされていません。
            <VStack alignItems={'flex-start'} my={'20px'} fontWeight={'bold'}>
              <Text>アカウント名: {providerAccount.displayName}</Text>
              <Text>
                登録日時:{' '}
                {dateTimeStringWithWeekDay(dayjs(providerAccount.createTime))}
              </Text>
              {trialEnd && (
                <Text>
                  無料トライアル終了日時: {dateTimeStringWithWeekDay(trialEnd)}
                </Text>
              )}
            </VStack>
            利用を継続する場合は、以下より支払い登録フォームへ進んでください。
            {providerAccount.status === 'attached' && (
              <>
                <br />
                もう使用されない場合は、退会ページから退会・解約をお願いいたします。
              </>
            )}
          </Box>
          <VStack
            p={'16px'}
            alignItems={'flex-start'}
            alignSelf={'center'}
            spacing={'32px'}
            w={'full'}
          >
            <Stack
              alignItems={{ base: 'flex-start', md: 'center' }}
              direction={{ base: 'column', md: 'row' }}
              w={'full'}
            >
              <Box display={'inline-block'} width={'10em'}>
                利用継続をご希望
              </Box>
              <Button
                as={Link}
                variant={'blue-fill'}
                to={Path.paymentRegistration}
              >
                {'支払い登録フォーム'}
              </Button>
            </Stack>
            {providerAccount.status === 'attached' && (
              <Stack
                alignItems={{ base: 'flex-start', md: 'center' }}
                direction={{ base: 'column', md: 'row' }}
                w={'full'}
              >
                <Box display={'inline-block'} width={'10em'}>
                  利用終了をご希望
                </Box>
                <Button as={Link} to={Path.churn} variant={'red-fill'}>
                  {'退会ページ'}
                </Button>
              </Stack>
            )}
            <Stack
              alignItems={{ base: 'flex-start', md: 'center' }}
              direction={{ base: 'column', md: 'row' }}
              w={'full'}
            >
              <Box display={'inline-block'} width={'10em'}>
                ログアウトする
              </Box>
              <Button
                colorScheme={'gray'}
                onClick={() => {
                  navigate('/logout');
                }}
              >
                {'ログアウト'}
              </Button>
            </Stack>
          </VStack>
        </VStack>
      ) : status === 'requires_action' ? (
        <>
          <VStack alignItems={'flex-start'} p={{ base: '8px', md: '16px' }}>
            <Box>
              お使いのアカウント({providerAccount.displayName}
              )で、ポチコ利用料の
              <Box fontWeight={'bold'}>お支払いに確認が必要です</Box>
            </Box>
            <VStack alignItems={'flex-start'}>
              <Button
                isLoading={onClickConfirmPaymentMutation.isPending}
                onClick={() =>
                  onClickConfirmPaymentMutation.mutateAsync(
                    unpaidStatus.needConfirmation
                  )
                }
              >
                お支払い確認
              </Button>
            </VStack>
            <br />
          </VStack>
          <Spacer height={'20px'} />
          <Link to={unpaidStatus.customerPortalUrl || '#'} isExternal>
            <Button
              m={4}
              isLoading={!unpaidStatus.customerPortalUrl}
              colorScheme={'green'}
            >
              請求ポータル
            </Button>
          </Link>
        </>
      ) : (
        // 遅延している請求 or 支払っていない請求がある場合
        <>
          <VStack alignItems={'flex-start'} p={{ base: '8px', md: '16px' }}>
            <Text>
              お使いのアカウント({providerAccount.displayName}
              )で、<strong>ポチコ利用料のお支払いが遅延しています。</strong>
            </Text>
            <VStack alignItems={'flex-start'}>
              <Text>【支払い方法】</Text>
              <Text>①請求ポータル内の下部「インボイスの履歴」を確認</Text>
              <Text>
                ②「期日超過」となっている列の「
                <Box display={'inline'}>
                  <StripeLinkButton />
                </Box>
                」をクリック
              </Text>
              <Text>③請求内容をご確認の上お支払いをお願いします。</Text>
            </VStack>
            <br />
            <Text>
              なお、「支払い方法」を登録しても、過去の請求についてお支払いは完了しませんのでご注意ください。
            </Text>
            {/* <br />
              お支払いが完了し、継続してポチコをご利用の場合は再度お支払い情報をご登録してください */}
          </VStack>
          <Spacer height={'20px'} />
          <Link to={unpaidStatus.customerPortalUrl || '#'} isExternal>
            <Button
              m={4}
              isLoading={!unpaidStatus.customerPortalUrl}
              colorScheme={'green'}
            >
              請求ポータル
            </Button>
          </Link>
        </>
      )}
    </RegistrationLayout>
  );
};
export default Unpaid;
