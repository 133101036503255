import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, dateStringWithWeekDayShort } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { BulkSpotAddHistory } from '../../../firebase/types';
import { useIsPC } from '../../../hooks/useIsPC';

type Props = {
  histories: BulkSpotAddHistory[];
  bookingMenu: BookingMenu | undefined;
  onSelect: (history: BulkSpotAddHistory) => void;
};

// 一括登録履歴を選択するためのUI
export const BulkAddSpotHistorySelect: React.FC<Props> = ({
  histories, // 事前に取得した履歴を与える
  bookingMenu,
  onSelect,
}) => {
  const isPC = useIsPC();
  const [selectedId, setSelectedId] = React.useState<string | undefined>(
    undefined
  );

  const _onSelect = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedId(e.target.value);
    },
    []
  );

  const _onClick = React.useCallback(() => {
    const history = histories.find((h) => h.id === selectedId);
    if (history) {
      onSelect(history);
      setSelectedId(undefined);
    }
  }, [histories, onSelect, selectedId]);

  const options = React.useMemo(() => {
    if (!bookingMenu) {
      return [];
    }
    return histories
      .filter((h) => h.bookingMenuId === bookingMenu.id)
      .slice(0, 10)
      .map((history) => {
        // const bookingMenu = bookingMenuList.find(
        //   (bm) => bm.id === history.bookingMenuId
        // );

        const createTime = dateStringWithWeekDayShort(
          dayjs(history.createTime)
        );
        const label = isPC
          ? `${history.duration.startDate.replace(
              '-',
              '/'
            )}〜${history.duration.endDate.replace(
              '-',
              '/'
            )}(実行日時:${createTime} 件数:${history.count}件)`
          : `${history.duration.startDate
              .slice(5)
              .replace('-', '/')}〜${history.duration.endDate
              .slice(5)
              .replace('-', '/')} (枠数:${history.count}件)`;
        return {
          label,
          value: history.id,
        };
      });
  }, [bookingMenu, histories, isPC]);

  return (
    <FormControl>
      <VStack w={'full'} alignItems="flex-start" spacing="8px">
        <FormLabel fontSize="14px" whiteSpace="nowrap">
          履歴から読み込む
        </FormLabel>
        <Text fontSize="xs" color="gray.600">
          {bookingMenu
            ? '過去の履歴から一括登録の設定を読み込むことができます。'
            : '予約メニューを選択してください'}
        </Text>
        {options.length > 0 && (
          <HStack width={'full'} spacing="8px">
            <Select onChange={_onSelect} value={selectedId}>
              <option value="">選択してください</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <Button
              onClick={_onClick}
              isDisabled={!selectedId}
              colorScheme="blue"
              size="md"
            >
              読み込む
            </Button>
          </HStack>
        )}
        {options.length === 0 && bookingMenu && (
          <Text fontSize="xs" color="gray.600">
            履歴がありません
          </Text>
        )}
      </VStack>
    </FormControl>
  );
};
