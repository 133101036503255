import 'dayjs/locale/ja';

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { BookingMenu, ProviderAccount, sleep } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import {
  FormProvider,
  Validate,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsPC } from '../../../hooks/useIsPC';

import { useQueryClient } from '@tanstack/react-query';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { BulkSpotAddHistory, DisplaySpot } from '../../../firebase/types';
import { dayOfWeeks } from '../../../helpers/dayOfWeeks';
import {
  useBulkSpotsCreate,
  useBulkSpotsValidator,
} from '../../../hooks/bulkSpot';
import { spotBulkAddHistoryQueryKey } from '../../../hooks/spotBulkAddHistories';
import { resourcePath } from '../../../hooks/useUrlPath';
import { RESOURCE_NAME } from '../../../providers/dataProvider';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { Layout } from '../../ui/Layout';
import { Link } from '../../ui/Link';
import { useLoadingOverlayContext } from '../../ui/LoadingOverlay';
import { WebLink } from '../../ui/WebLink';
import { ExcludeDaysInput } from './ExcludeDaysInput';
import { SpotSlotsInput } from './SpotSlotsInput';
import { readFromLocalStorage, writeToLocalStorage } from './localStorage';
import { BulkAddSpotsPreview } from './preview';
import { BulkAddSpotsInput, dateFormat } from './types';

const DuplicateSpotsList: React.FC<{
  bookingMenu: BookingMenu | undefined;
  duplicates: DisplaySpot[];
}> = ({ bookingMenu, duplicates }) => {
  return (
    <VStack alignItems={'flex-start'} maxH={'80vh'} w={'full'} spacing={'16px'}>
      <Text>
        指定した設定と重複する以下の予約枠がすでに作成済みです。
        <br />
        重複して予約枠を作成しますか？
      </Text>
      <Divider borderColor={'gray.200'} borderWidth={'1px'} />
      <Text fontWeight={'bold'}>予約メニュー: {bookingMenu?.name}</Text>
      <VStack alignItems={'flex-start'} overflowY={'auto'} w={'full'}>
        {duplicates
          .map((spot) => {
            return `${spot.displayDate} ${spot.startTime}`;
          })
          .uniq()
          .map((text, i) => {
            return <Text key={`duplicates-${i}`}>{text}</Text>;
          })}
      </VStack>
    </VStack>
  );
};

export const BulkAddSpots: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  bulkSpotAddHistoryList: BulkSpotAddHistory[];
}> = ({ providerAccount, bookingMenuList, bulkSpotAddHistoryList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [preview, setPreview] = React.useState(false);
  const mutation = useBulkSpotsCreate(providerAccount);
  const { checkDuplicates } = useBulkSpotsValidator(providerAccount);
  const toast = useToast();

  const now = React.useMemo(() => dayjs(), []);
  const defaultDuration = React.useMemo(() => {
    return {
      startDate: now.format(dateFormat),
      endDate: now.endOf('month').format(dateFormat),
    };
  }, [now]);
  const form = useForm<BulkAddSpotsInput>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      duration: defaultDuration,
      slots: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
      excludeDays: [],
    },
  });
  const {
    control,
    reset,
    trigger,
    getValues,
    clearErrors,
    setError,
    handleSubmit,
    formState: { isDirty },
  } = form;

  const slots = useWatch({
    name: 'slots',
    control: control,
  });

  const toConfirm = React.useCallback(async () => {
    if (!providerAccount) {
      console.error(`providerAccount not found`);
      return;
    }
    clearErrors('slots');
    const isValid = await trigger();
    const input = getValues();
    if (!isValid) {
      const firstError: keyof BulkAddSpotsInput | undefined = (
        Object.keys(form.formState.errors) as Array<keyof BulkAddSpotsInput>
      ).find((field) => {
        return field in form.formState.errors;
      });

      if (firstError) {
        form.setFocus(firstError);
      }
      return;
    }

    // URLにconfirm中であることを入れておく
    history.pushState(null, '', '#confirm');
    window.scrollTo(0, 0);
    setPreview(true);

    // previewに行くタイミングで保存しておく
    writeToLocalStorage(providerAccount, input);
  }, [clearErrors, form, getValues, providerAccount, trigger]);

  const backToEdit = React.useCallback(() => {
    // 履歴を戻りつつ#confirmを消す
    navigate(-1);
    history.pushState(null, '', location.pathname);
    setPreview(false);
  }, [location.pathname, navigate]);

  const selectedBookingMenuId = useWatch({
    control: control,
    name: 'bookingMenuId',
  });
  const bookingMenu = React.useMemo(() => {
    return bookingMenuList.find((menu) => menu.id === selectedBookingMenuId);
  }, [bookingMenuList, selectedBookingMenuId]);
  const queryClient = useQueryClient();
  const loadingOverlay = useLoadingOverlayContext();

  const { openConfirmationDialog } = useConfirmationDialog();
  const onSubmitConfirmed = React.useCallback(
    async (input: BulkAddSpotsInput) => {
      if (!loadingOverlay.isOpen) {
        loadingOverlay.onOpen();
      }
      mutation.mutateAsync(input, {
        onSuccess: async ({ data: { id, count } }) => {
          writeToLocalStorage(
            providerAccount,
            input
            // Object.assign({}, input, { duration: undefined }) // 作成が終わったら日時だけ消しておく
          );
          const filteredSpotListPath = resourcePath({
            providerAccount,
            resourceName: 'spot',
            action: 'list',
            urlParams: {
              tab: 'list',
              bookingMenuId: input.bookingMenuId,
              displayDate: JSON.stringify({
                start: input.duration.startDate,
                end: input.duration.endDate,
              }),
              bulkSpotAddHistoryId: id,
            },
          });
          const toastId = toast({
            status: 'success',
            title: (
              <VStack alignItems={'flex-start'}>
                <Text>合計{count}個の予約枠を一括登録しました</Text>
                <Link
                  to={filteredSpotListPath}
                  color={'white'}
                  _hover={{
                    textDecoration: 'underline',
                    color: 'gray.200',
                  }}
                  textDecoration={'underline'}
                  onClick={() => toast.close(toastId)}
                >
                  作成した予約枠を確認する
                </Link>
              </VStack>
            ),
            duration: 5000,
          });
          queryClient.invalidateQueries({
            queryKey: spotBulkAddHistoryQueryKey.list(providerAccount).queryKey,
          });
          await sleep(1000);
          loadingOverlay.onClose();
          backToEdit(); // 編集画面に戻る
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: `予約枠の一括登録に失敗しました: ${error}`,
          });
          loadingOverlay.onClose();
        },
      });
    },
    [backToEdit, loadingOverlay, mutation, providerAccount, queryClient, toast]
  );
  const onSubmit = React.useCallback(
    async (input: BulkAddSpotsInput) => {
      if (!providerAccount) {
        console.error('not ready to submit. providerAccount not found');
        return;
      }
      if (!preview) {
        console.error('not in preview mode');
        return;
      }
      loadingOverlay.onOpen();
      try {
        const duplicates = await checkDuplicates(input);
        if (duplicates.length > 0) {
          openConfirmationDialog({
            title: '重複する予約枠があります',
            body: (
              <DuplicateSpotsList
                bookingMenu={bookingMenu}
                duplicates={duplicates}
              />
            ),
            cancelText: 'キャンセル',
            submitText: '作成する',
            submitButtonDisabledDurationSec: 2,
            onSubmit: () => onSubmitConfirmed(input),
            onCancel: () => {
              loadingOverlay.onClose();
            },
          });
        } else {
          onSubmitConfirmed(input);
        }
      } catch (error) {
        loadingOverlay.onClose();
        return;
      }
      return;
    },
    [
      bookingMenu,
      checkDuplicates,
      loadingOverlay,
      onSubmitConfirmed,
      openConfirmationDialog,
      preview,
      providerAccount,
    ]
  );

  React.useEffect(() => {
    const allEmpty = dayOfWeeks.every(({ id }) => slots[id].length === 0);
    if (isDirty && allEmpty) {
      setError(`slots`, {
        type: 'required',
        message: '未入力です',
      });
    } else {
      clearErrors('slots');
    }
  }, [clearErrors, form, isDirty, setError, slots]);

  React.useEffect(() => {
    if (!providerAccount) {
      return;
    }
    const storedInput = readFromLocalStorage(providerAccount);
    if (storedInput) {
      reset({
        ...storedInput,
        duration: storedInput.duration || defaultDuration,
      });
      if (mutation.isIdle) {
        toast.closeAll();
        toast({
          status: 'info',
          title: `前回の入力を読み込みました`,
        });
        trigger();
      }
    }
  }, [
    defaultDuration,
    trigger,
    providerAccount,
    toast,
    reset,
    mutation.status,
    mutation.isIdle,
  ]);

  React.useEffect(() => {
    // ページ読み込み時に#confirmを消す
    history.pushState(null, '', location.pathname);
    const switchPreviewMode = () => {
      // previewを見るボタンを押した場合にのみpreviewできる
      setPreview(location.hash === '#confirm');
    };
    // #confirmにいるときにブラウザリロードしても問題ない
    window.addEventListener('popstate', switchPreviewMode, false);
    return () => {
      window.removeEventListener('popstate', switchPreviewMode, false);
    };
  }, [location]);

  if (bookingMenuList.length === 0) {
    return <MissingBookingMenus providerAccount={providerAccount} />;
  }

  return (
    <Layout pageTitle="予約枠の一括登録">
      <VStack
        // width={{ base: '342px', lg: '891px' }}
        w={'full'}
        alignSelf={{ base: 'center', lg: 'normal' }}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        spacing={'20px'}
      >
        <FormProvider {...form}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <VStack
              width={'full'}
              // borderWidth={'1px'}
              // borderColor={'gray.200'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              spacing={'10px'}
            >
              {preview ? (
                bookingMenu ? (
                  <BulkAddSpotsPreview
                    input={getValues()}
                    bookingMenu={bookingMenu}
                    isSubmitting={mutation.isPending}
                    onSubmit={
                      () =>
                        console.log(
                          'onSubmit in preview clicked'
                        ) /* noop. submit typeなボタンのonClickでonSubmitが実行される */
                    }
                    onCancel={backToEdit}
                  />
                ) : (
                  <Text>選択した予約メニューが存在しません</Text>
                )
              ) : (
                <BulkAddSpotsEditor
                  providerAccount={providerAccount}
                  bookingMenuList={bookingMenuList}
                  bulkSpotAddHistoryList={bulkSpotAddHistoryList}
                  onCompleteInput={toConfirm}
                />
              )}
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </Layout>
  );
};

const BulkAddSpotsEditor: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  bulkSpotAddHistoryList: BulkSpotAddHistory[];
  onCompleteInput: () => void;
}> = ({
  providerAccount,
  bookingMenuList,
  bulkSpotAddHistoryList,
  onCompleteInput,
}) => {
  const { control, reset, getValues } = useFormContext<BulkAddSpotsInput>();
  const now = React.useMemo(() => dayjs(), []);
  const isPC = useIsPC();
  const onSelectHistory = React.useCallback(
    (history: BulkSpotAddHistory) => {
      const currentInput = getValues();
      const newValues = {
        ...currentInput,
        slots: history.slots,
      };
      console.log('newValues', newValues);
      reset(newValues);
    },
    [getValues, reset]
  );
  const bookingMenuId = useWatch({
    control: control,
    name: 'bookingMenuId',
  });
  const bookingMenu = React.useMemo(() => {
    return bookingMenuList.find((menu) => menu.id === bookingMenuId);
  }, [bookingMenuId, bookingMenuList]);

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      spacing={'18px'}
      justifyContent={'space-between'}
    >
      <VStack
        w={'full'}
        borderWidth={{ base: 0, md: '1px' }}
        alignItems={'flex-start'}
        borderColor={'gray.200'}
        borderRadius={'4px'}
        padding={{ base: 0, md: '16px' }}
        spacing={{ base: '40px', md: '20px' }}
        backgroundColor={'white'}
        divider={isPC ? <Divider borderColor={'gray.300'} /> : undefined}
      >
        <BookingMenuInput
          providerAccount={providerAccount}
          bookingMenuList={bookingMenuList}
        />
        <DurationInput now={now} />
        <ExcludeDaysInput now={now} />

        <SpotSlotsInput
          bulkSpotAddHistoryList={bulkSpotAddHistoryList}
          bookingMenu={bookingMenu}
          onSelectHistory={onSelectHistory}
        />
      </VStack>
      <VStack w={'full'} alignItems={'flex-end'}>
        <Button type={'button'} variant={'blue-fill'} onClick={onCompleteInput}>
          内容を確認する
          <MdOutlineNavigateNext />
        </Button>
      </VStack>
    </VStack>
  );
};

const MissingBookingMenus = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  return (
    <Box>
      先に
      <WebLink
        href={`/${providerAccount.id}/${providerAccount.botId}/${RESOURCE_NAME.BOOKING_MENU}`}
      >
        こちらから
      </WebLink>{' '}
      予約メニューを作成して下さい
    </Box>
  );
};

const BookingMenuInput: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BulkAddSpotsInput>();
  React.useEffect(() => {
    if (!providerAccount.needBookingMenu) {
      const defaultBookingMenu = bookingMenuList.find((menu) => menu.isDefault);
      if (defaultBookingMenu) {
        setValue('bookingMenuId', defaultBookingMenu.id);
      }
    }
  }, [bookingMenuList, providerAccount.needBookingMenu, setValue]);

  return (
    <FormControl isRequired={true} isInvalid={!!errors.bookingMenuId}>
      <VStack direction={'column'} alignItems={'flex-start'}>
        <FormLabel fontSize={'16px'} fontWeight={'bold'}>
          ① 登録したい予約メニューを選んでください
        </FormLabel>
        <VStack
          // width={'full'}
          direction={'column'}
          alignItems={'flex-start'}
          marginTop={'12px'}
        >
          {/* <FormLabel>予約メニュー</FormLabel> */}
          <Select
            placeholder="予約メニューを選択"
            {...register('bookingMenuId', {
              required: '選択してください',
            })}
          >
            {bookingMenuList.map((bookingMenu: BookingMenu) => {
              // TODO: statusを見る？
              return (
                <option key={bookingMenu.id} value={bookingMenu.id}>
                  {bookingMenu.name}
                </option>
              );
            })}
          </Select>
          <FormErrorMessage>{errors.bookingMenuId?.message}</FormErrorMessage>
        </VStack>
      </VStack>
    </FormControl>
  );
};

const DurationInput: React.FC<{ now: dayjs.Dayjs }> = ({ now }) => {
  const {
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<BulkAddSpotsInput>();
  const onClickDurationShortcut = (value: 'thisMonth' | 'nextMonth') => {
    switch (value) {
      case 'thisMonth':
        setValue(
          'duration',
          {
            startDate: now.format(dateFormat),
            endDate: now.endOf('month').format(dateFormat),
          },
          {
            shouldDirty: true,
          }
        );
        break;

      case 'nextMonth':
        setValue(
          'duration',
          {
            startDate: now.add(1, 'month').startOf('month').format(dateFormat),
            endDate: now.add(1, 'month').endOf('month').format(dateFormat),
          },
          {
            shouldDirty: true,
          }
        );
        break;

      default:
        break;
    }
    clearErrors('duration');
  };

  const validateEndDate: Validate<string, BulkAddSpotsInput> =
    React.useCallback(
      (value: string, values: BulkAddSpotsInput) => {
        const now = dayjs();
        clearErrors('duration');
        if (value < now.format('YYYY-MM-DD')) {
          setError('duration.endDate', {
            message: '今日以降の日付を指定してください',
            type: 'custom',
          });
          return '今日以降の日付を指定してください';
        }

        if (value < values.duration.startDate) {
          setError('duration.endDate', {
            message: '終了日には開始日より後の日付を指定してください',
            type: 'custom',
          });
          return '終了日には開始日より後の日付を指定してください';
        }

        return true;
      },
      [clearErrors, setError]
    );

  // React.useEffect(() => {
  //   onChangeInput(watch);
  // }, [onChangeInput]);

  return (
    <FormControl isRequired={true} isInvalid={!!errors.duration}>
      <Flex direction={'column'} alignItems={'flex-start'}>
        <FormLabel fontSize={'16px'} fontWeight={'bold'}>
          ② 登録したい期間を選択してください
        </FormLabel>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          // minChildWidth={'300px'}
          // columns={{ base: 1, lg: 2 }}
          gap={'8px'}
          spacing={0}
          width={'full'}
          alignItems={{ base: 'flex-start', lg: 'center' }}
        >
          <HStack
            justifyContent={'flex-start'}
            alignItems={'flex-end'}
            w={'fit-content'}
            marginTop={4}
            // flexWrap={'wrap'}
          >
            <FormControl
              isRequired={true}
              isInvalid={!!errors.duration?.startDate}
            >
              <FormLabel>開始日</FormLabel>
              <Input
                // maxWidth={'full'}
                type={'date'}
                w={'8em'}
                px={2}
                paddingRight={1}
                placeholder={now.format('YYYY-MM-DD')}
                {...register('duration.startDate', {
                  // valueAsDate: true, // これを指定するとうまくいかない
                  required: true,
                  // onBlur: async () => {
                  //   onChangeInput('startDate');
                  // },
                })}
              />
              {/* <FormErrorMessage>
                {errors.duration?.startDate?.message}
              </FormErrorMessage> */}
            </FormControl>

            <Text h={'2em'}>〜</Text>
            <FormControl
              w={'fit-content'}
              isRequired={true}
              isInvalid={!!errors.duration?.endDate}
            >
              <FormLabel>終了日</FormLabel>
              <Input
                // maxWidth={'full'}
                type="date"
                w={'8em'}
                px={'4px'}
                placeholder={now.endOf('month').format('YYYY-MM-DD')}
                {...register('duration.endDate', {
                  required: true,
                  validate: validateEndDate,
                })}
              />
              {/* <FormErrorMessage>
                {errors.duration?.endDate?.message}
              </FormErrorMessage> */}
            </FormControl>
          </HStack>
          <HStack
            paddingLeft={{ base: 0, lg: '16px' }}
            paddingTop={{ base: 0, lg: '2.5em' }}
            spacing={'8px'}
            whiteSpace={'nowrap'}
            // h={'2.5em'}
          >
            <Button
              variant={'link'}
              onClick={() => onClickDurationShortcut('thisMonth')}
            >
              今月
            </Button>
            <Button
              variant={'link'}
              onClick={() => onClickDurationShortcut('nextMonth')}
            >
              来月
            </Button>
          </HStack>
        </Stack>
        <FormErrorMessage>{errors.duration?.endDate?.message}</FormErrorMessage>
      </Flex>
    </FormControl>
  );
};
