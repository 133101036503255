import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMenuState,
} from '@chakra-ui/react';
import {
  Booking,
  BookingFormElementInput,
  BookingMenu,
  LineUser,
  ProviderAccount,
  waitPromise,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
// import { useSavedFilterContext } from '../resourceFilter/SavedFilter';

import { MdExpandMore } from 'react-icons/md';
import { useBookingListFilter } from '../../../hooks/booking';
import { useFetchAllLineUsers } from '../../../hooks/lineUser';
import { getBookingList } from '../../../providers/dataProvider/booking';
import { Sort } from '../../../providers/dataProvider/type';
import {
  CsvDownloadButton,
  CsvDownloadHeaders,
} from '../../ui/CsvDownloadButton';

export const BookingListMenu: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  totalCount: number;
  sort: Sort<Booking>;
}> = ({ providerAccount, bookingMenuList, totalCount, sort }) => {
  return (
    <Box>
      <Menu isLazy initialFocusRef={undefined}>
        <MenuButton
          as={Button}
          variant={'soft-fill'}
          colorScheme="blue"
          leftIcon={<MdExpandMore />}
        >
          その他の機能
        </MenuButton>
        <MenuList autoFocus={false}>
          <MenuItem closeOnSelect={false} autoFocus={false} as={Box} p={0}>
            <BookingListCsvDownloadButton
              providerAccount={providerAccount}
              bookingMenuList={bookingMenuList}
              totalCount={totalCount}
              sort={sort}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const BookingListCsvDownloadButton = ({
  providerAccount,
  bookingMenuList,
  totalCount,
  sort,
}: {
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  totalCount: number;
  sort: Sort<Booking>;
}) => {
  const { onClose } = useMenuState();
  const lineUsersQuery = useFetchAllLineUsers({
    providerAccount,
    opts: { enabled: false },
  });
  const createHeaders = React.useCallback(
    (lineUsers: LineUser[]) => {
      const hasFormResponse = bookingMenuList.some(
        (menu) => menu.bookingForm?.enabled
      );
      return [
        { field: 'id', label: '予約ID' },
        { field: 'spotId', label: '予約枠ID' },
        { field: 'displayDate', label: '予約日' },
        { field: 'startTime', label: '開始時間' },
        {
          field: (booking) => {
            const bookingMenu = bookingMenuList.find(
              (bm) => bm.id === booking.bookingMenu.id
            );
            return bookingMenu?.name || booking.bookingMenu.name;
          },
          label: '予約メニュー',
        },
        {
          field: (booking) => {
            return booking.lineUserId || '';
            // LMP連携のときは不要な部分を取り除いて提供することも考えたが、他の場所と整合性を取るのが面倒なのでそのまま出す
            // if (!booking.lineUserId) {
            //   return '';
            // }
            // if (providerAccount.lineChannelType === 'messagingApi') {
            //   return booking.lineUserId;
            // } else {
            //   const splitted = booking.lineUserId.split('-');
            //   if (splitted.length === 2) {
            //     return splitted[1];
            //   } else {
            //     return booking.lineUserId;
            //   }
            // }
          },
          label: '予約ユーザーID',
        },
        {
          field: (booking) => {
            if (booking.userName) {
              return booking.userName;
            }
            const lineUser = lineUsers.find(
              (lu) => lu.id === booking.lineUserId
            );
            return (
              lineUser?.displayNameByProvider ||
              lineUser?.displayName ||
              booking.lineUserId ||
              ''
            );
          },
          label: '予約ユーザー名',
        },
        ...[
          hasFormResponse
            ? {
                field: (b: Booking) => {
                  const inputs = b.formResponse?.inputs;
                  if (!inputs) {
                    return '';
                  }
                  return inputs
                    .reduce((acc, elementInput) => {
                      const _input = displayBookingFormInput(elementInput);
                      acc.push(`・ ${elementInput.element.title}: ${_input}`);
                      return acc;
                    }, [] as string[])
                    .join('\n');
                },
                label: 'フォーム回答',
              }
            : {},
        ],
        {
          field: (b) => b.providerMemo || '',
          label: '予約メモ',
        },
        {
          field: (b) => dayjs(b.createTime).format('YYYY-MM-DD HH:mm:ss'),
          label: '作成日時',
        },
      ] as CsvDownloadHeaders<Booking>;
    },
    [bookingMenuList]
  );
  const { filter: bookingFilter } = useBookingListFilter();
  const query = React.useCallback(async () => {
    console.log('csv download query', { bookingFilter, totalCount, sort });
    const { data } = await waitPromise(lineUsersQuery.refetch(), 30000);
    const headers = createHeaders(data || []);
    const results = await getBookingList(providerAccount.id, {
      filter: bookingFilter,
      pagination: {
        perPage: totalCount,
        sort: sort,
      },
    }).then(({ data }) => data);
    return { headers, data: results };
  }, [
    bookingFilter,
    createHeaders,
    lineUsersQuery,
    providerAccount.id,
    sort,
    totalCount,
  ]);

  return (
    <CsvDownloadButton<Booking>
      isDisabled={totalCount === 0}
      variant={'soft-fill'}
      colorScheme="transparent"
      queryKey="booking"
      query={query}
      onDownloadComplete={onClose}
    />
  );
};

const displayBookingFormInput = (elementInput: BookingFormElementInput) => {
  const {
    element: { type },
    input,
  } = elementInput;
  if (type === 'sex' || type === 'single-select') {
    if (!input) {
      return '未回答';
    }
    return input;
  } else if (type === 'multi-select') {
    if (!input || input === '[]') {
      return '未回答';
    }
    return JSON.parse(input).join(', ');
  } else if (type === 'agreement') {
    return input === 'true' ? 'チェック済み' : 'チェックなし';
  } else {
    return input;
  }
};
