import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Contract, getPlan, ProviderAccount, sleep } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { useAuthState } from '../../../context/providerAccount';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { useStripeConfirmPayment } from '../../../hooks/useStripeConfirmPayment';
import { Path } from '../../../routers/Path';
import Footer from '../../ui/Footer';
import { Layout } from '../../ui/Layout';
import { PochicoPaymentMethod } from './createSubscription';
import { useCreateSubscription } from './useCreateSubscription';
import { useFetchSetupIntent } from './useFetchSetupIntentClientSecret';

export const PaymentMethodConfirmed = () => {
  const { refetch, initialized, firebaseUser, providerAccount } =
    useAuthState();

  if (!initialized) {
    return <Spinner />;
  }
  if (!firebaseUser || !providerAccount) {
    // ちゃんとロードされた後でこれらがなかったら未ログイン
    alert(
      'ログインしていないか、ログインが時間切れになっています。再度ログインしてください。'
    );
    return <Navigate to={Path.login} />;
  }
  return (
    <StripeElementWrapper>
      <Inner providerAccount={providerAccount} firebaseUser={firebaseUser} />
    </StripeElementWrapper>
  );
};

const STRIPE_API_KEY = GLOBAL_CONFIG.STRIPE.STRIPE_API_KEY;

const stripePromise = loadStripe(STRIPE_API_KEY);
const StripeElementWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

const Inner: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const setupIntentQuery = useFetchSetupIntent();
  const { refetch, initialized } = useAuthState();
  const [searchParams] = useSearchParams();

  const contract: Contract = React.useMemo(() => {
    const plan = (() => {
      if (providerAccount) {
        return getPlan(providerAccount);
      }
      return 'standard';
    })();
    return { plan };
  }, [providerAccount]);
  const toast = useToast();
  const navigate = useNavigate();
  const createSubscription = useCreateSubscription();
  const mutation = useMutation({
    retry: 0,
    mutationFn: async (paymentMethod: PochicoPaymentMethod) => {
      return createSubscription(paymentMethod, contract);
    },
    onSuccess: async (result) => {
      console.log({ message: '支払い登録が完了', result });
      toast({
        title:
          '支払い登録が完了しました。メールアドレス認証のため、メールをご確認ください。',
        status: 'success',
      });
      await Promise.all([sleep(2000), refetch()]);
      // window.location.href = Path.home;
      navigate('/');
    },
    onError: (e: Error) => {
      if (e.message === 'string' && e.message.includes('already registered')) {
        toast({
          title: 'すでに支払い登録済みです。管理画面へ戻ります。',
          status: 'info',
        });
        navigate('/');
        return;
      }
      console.error('onError', {
        providerAccount: providerAccount.id,
        error: e,
      });
      toast({
        status: 'error',
        title: `エラーが発生しました。error: ${e.message}`,
      });
    },
  });

  const alreadyRegistered =
    initialized &&
    providerAccount.paymentStatus === 'registered' &&
    providerAccount.stripeCustomerId &&
    (providerAccount.stripeSubscriptionId ||
      providerAccount.stripeSubscriptionScheduleId);

  useEffect(() => {
    console.log('useEffect 1', {
      alreadyRegistered,
      mutation: mutation.status,
    });
    if (mutation.status === 'idle' && alreadyRegistered) {
      toast.closeAll();
      if (providerAccount.status === 'attached') {
        toast({
          title: '支払い登録が完了しています。管理画面へ戻ります。',
        });
        navigate('/');
        return;
        // window.location.href = '/';
      } else {
        toast({
          title: 'LINE連携が切れてします。再度連携してください。',
          status: 'warning',
        });
        navigate('/line-oa-link');
        return;
      }
    }
  }, [
    alreadyRegistered,
    mutation.isSuccess,
    mutation.status,
    navigate,
    providerAccount.status,
    toast,
  ]);

  useEffect(() => {
    console.log('useEffect 2', { alreadyRegistered });
    if (alreadyRegistered) {
      return;
    }
    const invoiceEmail = decodeURIComponent(
      searchParams.get('invoiceEmail') || ''
    );
    console.log('useEffect 2', {
      invoiceEmail,
      setupIntentQuery: {
        status: setupIntentQuery.status,
        data: setupIntentQuery.data,
      },
    });
    if (
      mutation.status === 'idle' &&
      invoiceEmail &&
      setupIntentQuery.data &&
      setupIntentQuery.status === 'success' &&
      typeof setupIntentQuery.data.payment_method === 'string'
    ) {
      mutation.mutateAsync({
        type: 'card',
        paymentMethodId: setupIntentQuery.data.payment_method,
        invoiceEmail,
      });
    } else {
      console.log('skipped mutation', {
        alreadyRegistered,
        status: mutation.status,
        invoiceEmail,
        setupIntentQuery: {
          status: setupIntentQuery.status,
          data: setupIntentQuery.data,
        },
      });
    }
  }, [alreadyRegistered, mutation, searchParams, setupIntentQuery]);
  const confirmation = useStripeConfirmPayment();
  const onClickToRetryConfirmationMutation = useMutation({
    mutationFn: React.useCallback(async () => {
      if (
        !mutation.error ||
        !('error' in mutation.error) ||
        !mutation.error.error
      ) {
        return;
      }
      const mutationError = mutation.error.error as any;
      if (
        'payment_intent' in mutationError &&
        (mutationError.payment_intent as Stripe.PaymentIntent).client_secret &&
        typeof setupIntentQuery.data?.payment_method === 'string'
      ) {
        confirmation.mutateAsync({
          paymentIntentClientSecret: (
            mutationError.payment_intent as Stripe.PaymentIntent
          ).client_secret as string,
          paymentMethodId: setupIntentQuery.data?.payment_method,
        });
      } else {
        console.log('cannot retry', {
          paymentIntent: mutationError.payment_intent,
          setupIntentData: setupIntentQuery.data?.payment_method,
        });
      }
    }, [confirmation, mutation.error, setupIntentQuery.data?.payment_method]),
  });
  const currentStatus = React.useMemo(() => {
    if (alreadyRegistered) {
      return <Box>すでに登録済みです。管理画面へ戻ります。</Box>;
    }
    if (setupIntentQuery.isLoading) {
      return (
        <HStack>
          <Box>情報の確認中です。</Box>
          <Spinner />
        </HStack>
      );
    }
    if (setupIntentQuery.isError) {
      return <Box>エラーが発生しました。{String(setupIntentQuery.error)}</Box>;
    }

    if (mutation.isPending) {
      return (
        <HStack>
          <Box>支払い方法の登録中です、お待ち下さい。</Box>
          <Spinner />
        </HStack>
      );
    }
    if (mutation.isSuccess) {
      return (
        <HStack>
          <Box>支払い方法の登録が完了しました。管理画面へ戻ります。</Box>;
          <Spinner />
        </HStack>
      );
    }
    if (mutation.isError) {
      const errorObj = mutation.error as any;
      if (errorObj?.error && typeof errorObj.error === 'object') {
        const mutationError = errorObj.error;
        const canRetry =
          typeof mutationError === 'object' &&
          'payment_intent' in mutationError &&
          (mutationError.payment_intent as Stripe.PaymentIntent)
            ?.client_secret &&
          typeof setupIntentQuery.data?.payment_method === 'string';

        return (
          <VStack alignItems={'flex-start'}>
            <Box>エラーが発生しました。</Box>
            <Box color={'red.500'}>{String(mutationError.message)}</Box>
            <Button
              w={'fit-content'}
              variant={'white-red'}
              onClick={() => {
                onClickToRetryConfirmationMutation.mutateAsync();
              }}
            >
              {canRetry
                ? '再度認証を行う'
                : '各種設定の「請求ポータル」から支払い方法のご確認をお願いします'}
            </Button>
          </VStack>
        );
      }
      return (
        <VStack alignItems={'flex-start'}>
          <Box>エラーが発生しました。</Box>
          <Box color={'red.500'}>{JSON.stringify(mutation.error)}</Box>
        </VStack>
      );
    }
    return <Box>サーバーからの応答がありません。もう一度お試しください。</Box>;
  }, [
    alreadyRegistered,
    mutation.error,
    mutation.isError,
    mutation.isPending,
    mutation.isSuccess,
    onClickToRetryConfirmationMutation,
    setupIntentQuery.data?.payment_method,
    setupIntentQuery.error,
    setupIntentQuery.isError,
    setupIntentQuery.isLoading,
  ]);

  return (
    <Layout
      pageTitle={
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text fontSize={'sm'}>かんたん予約『ポチコ』</Text>
          <Text fontSize={'xl'} fontWeight={'bold'}>
            支払い登録
          </Text>
        </VStack>
      }
      hasBackButton={false}
    >
      <VStack w={{ base: 'full', md: '840px' }} alignItems={'flex-start'}>
        {currentStatus}
      </VStack>
      <Footer />
    </Layout>
  );
};
