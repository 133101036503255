import { Contract } from '@pochico/shared';
import { useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../../../context/providerAccount';
import { createSubscription, PochicoPaymentMethod } from './createSubscription';

export const useCreateSubscription = () => {
  const stripe = useStripe();
  const { firebaseUser, providerAccount } = useAuthState();
  const navigate = useNavigate();

  const f = React.useCallback(
    async (paymentMethod: PochicoPaymentMethod, contract: Contract) => {
      if (!stripe) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const firebaseUid = firebaseUser?.uid;
      if (!firebaseUid || !providerAccount?.id) {
        console.error(
          `[submit]firebaseUid or providerAccountId is undefined. firebaseUid: ${firebaseUid} / providerAccountId: ${providerAccount?.id}`
        );
        alert(
          'ログインしていないか、ログインが時間切れになっています。再度ログインしてください。'
        );
        navigate('/login');
        return;
      }

      console.log('[PaymentMethod]', paymentMethod);

      // If a previous payment was attempted, get the latest invoice
      // const latestInvoicePaymentIntentStatus = localStorage.getItem(
      //   'latestInvoicePaymentIntentStatus'
      // );
      // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      //   // Update the payment method and retry invoice payment
      //   const invoiceId = localStorage.getItem('latestInvoiceId');
      //   if (invoiceId) {
      //     const paymentMethodId =
      //       paymentMethod.type === 'card'
      //         ? paymentMethod.paymentMethodId
      //         : undefined;
      //     if (!paymentMethodId) {
      //       // こないはず
      //       throw new Error(
      //         `unknown status error. paymentMethod has not been registered but payment method required. invoiceId: ${invoiceId}`
      //       );
      //     }
      //     return retryInvoiceWithNewPaymentMethod(stripe, {
      //       providerAccountId: providerAccount.id,
      //       firebaseUid,
      //       paymentMethodId,
      //       invoiceId,
      //       priceId: PRICE_ID,
      //       invoiceEmail: paymentMethod.invoiceEmail,
      //     });
      //   }
      // } else {
      // Create the subscription
      return createSubscription(
        stripe,
        providerAccount.id,
        firebaseUser,
        paymentMethod,
        contract
      );
      // }
    },
    [firebaseUser, navigate, providerAccount?.id, stripe]
  );
  return f;
};
