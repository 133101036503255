import { HStack, Image } from '@chakra-ui/react';
import React from 'react';

export const LineAttachImage = () => {
  return (
    <HStack
      w={'full'}
      spacing={{ base: '8px', md: '24px' }}
      justifyContent={'center'}
    >
      <Image
        w={{ base: '80px', md: '108px' }}
        src={'/assets/pochico-logo-square.png'}
      />
      <Image w={{ base: '80px', md: '108px' }} src={'/assets/dual-arrow.png'} />
      <Image
        w={{ base: '80px', md: '108px' }}
        src={'/assets/LINE_Brand_icon.png'}
      />
    </HStack>
  );
};
