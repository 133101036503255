import { Button, ButtonProps, Text, VStack, useToast } from '@chakra-ui/react';
import { BookingForm, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteBookingForm } from '../../../../hooks/bookingForms';
import { useIsPC } from '../../../../hooks/useIsPC';
import { useConfirmationDialog } from '../../../ui/ConfirmationDialog';

export const DeleteBookingFormButton: React.FC<
  {
    providerAccount: ProviderAccount;
    bookingForm: BookingForm;
  } & ButtonProps
> = ({ providerAccount, bookingForm, ...props }) => {
  const deleteMutation = useDeleteBookingForm(providerAccount);
  const { openConfirmationDialog } = useConfirmationDialog();
  const toast = useToast();
  const navigate = useNavigate();
  const onClickDelete = React.useCallback(async () => {
    openConfirmationDialog({
      title: 'フォームを削除しますか？',
      body: (
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text>削除したフォームは元に戻すことはできません。</Text>
        </VStack>
      ),
      cancelText: 'キャンセル',
      submitText: '削除する',
      submitButtonDisabledDurationSec: 1,
      onSubmit: async () => {
        return deleteMutation
          .mutateAsync(bookingForm.id)
          .then(() => {
            toast({
              title: `${bookingForm.name} を削除しました`,
              status: 'success',
            });
            // navigate(
            //   resourcePath({
            //     providerAccount,
            //     resourceName: 'bookingForm',
            //     action: 'list',
            //   })
            // );
          })
          .catch((e) => {
            toast({
              title: `${bookingForm.name} の削除に失敗しました error: ${e}`,
              status: 'error',
            });
          });
      },
    });
  }, [bookingForm, deleteMutation, openConfirmationDialog, toast]);
  const isPC = useIsPC();

  return (
    <Button
      {...props}
      w={isPC ? 'fit-content' : 'full'}
      size={'sm'}
      variant={'white-red'}
      isLoading={deleteMutation.isPending}
      onClick={onClickDelete}
    >
      削除する
    </Button>
  );
};
