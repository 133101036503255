import {
  Divider,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { useIsPC } from '../../../hooks/useIsPC';
import { BookingFilter } from '../Bookings';
import { ResourceFilter } from '../resourceFilter/ResourceFilter';

export const BookingFilterInputs: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  filter: BookingFilter;
  applyFilter: (filter: BookingFilter) => void;
}> = ({ providerAccount, bookingMenus, filter, applyFilter }) => {
  const filterToComponent = React.useMemo(
    () => savedFilterToComponent(bookingMenus),
    [bookingMenus]
  );
  const filterToString = React.useMemo(
    () => (filter: BookingFilter) => {
      const bookingMenu = bookingMenus.find(
        (menu) => menu.id === filter.bookingMenuId
      );
      const strs = [];
      if (filter.displayDate) {
        const { start, end } = filter.displayDate;
        if (start && end) {
          strs.push(`${start} ~ ${end}`);
        } else if (start) {
          strs.push(`${start} ~`);
        } else if (end) {
          strs.push(`~ ${end}`);
        }
      }
      if (bookingMenu) {
        strs.push(bookingMenu.name);
      }
      return strs.join(' / ');
    },
    [bookingMenus]
  );
  return (
    <ResourceFilter
      defaultValue={filter}
      savedFilter={{
        filterKey: 'booking',
        toComponent: filterToComponent,
      }}
      applyFilter={applyFilter}
      filterToString={filterToString}
    >
      {({ filter, setFilter }) => (
        <BookingFilterInputInner
          providerAccount={providerAccount}
          bookingMenus={bookingMenus}
          filter={filter}
          onFilterChange={setFilter}
        />
      )}
    </ResourceFilter>
  );
};

const BookingFilterInputInner: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  filter: BookingFilter;
  onFilterChange: (filter: BookingFilter) => void;
}> = ({ providerAccount, bookingMenus, filter, onFilterChange }) => {
  const isPC = useIsPC();
  const today = React.useMemo(() => dayjs(), []);
  const [startInput, setStartInput] = React.useState<string | undefined>(
    () => filter.displayDate?.start || today.format('YYYY-MM-DD')
  );
  const [endInput, setEndInput] = React.useState<string | undefined>(
    () => filter.displayDate?.end || today.add(1, 'M').format('YYYY-MM-DD')
  );

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '8px', md: '24px' }}
      justifyContent={'center'}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      divider={<Divider orientation={isPC ? 'vertical' : 'horizontal'} />}
      fontSize={{ base: 'sm', lg: 'md' }}
      flexWrap={'wrap'}
    >
      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          予約日指定
        </Text>

        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Input
            paddingX={'2px'}
            w={'8em'}
            textAlign={'center'}
            type={'date'}
            value={startInput}
            // value={filter.displayDate.start}
            onChange={(e) => {
              const start = e.target.value;
              if (start > '2022-01-01') {
                const end = (() => {
                  if (
                    filter.displayDate?.end &&
                    start > filter.displayDate.end
                  ) {
                    return dayjs(start).add(1, 'day').format('YYYY-MM-DD');
                  } else {
                    return filter.displayDate?.end;
                  }
                })();
                onFilterChange({
                  ...filter,
                  displayDate: { start, end },
                });
                setEndInput(end);
              }
              setStartInput(start);
            }}
          />

          <HStack>
            <Text>〜</Text>
            <Input
              paddingX={'2px'}
              minW={'8em'}
              w={'fit-content'}
              textAlign={'center'}
              type={'date'}
              value={endInput}
              // value={filter.displayDate.end}
              onChange={(e) => {
                const end = e.target.value;
                if (end > '2022-01-01') {
                  const start = (() => {
                    if (
                      filter.displayDate?.start &&
                      end < filter.displayDate?.start
                    ) {
                      return dayjs(end).add(-1, 'day').format('YYYY-MM-DD');
                    } else {
                      return filter.displayDate?.start;
                    }
                  })();
                  onFilterChange({
                    ...filter,
                    displayDate: { start, end },
                  });
                  setStartInput(start);
                }
                setEndInput(end);
              }}
            />
          </HStack>
        </Stack>
      </Stack>

      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          予約メニュー
        </Text>

        <Select
          // w={'50%'}
          minW={'8em'}
          w={'fit-content'}
          placeholder={'フィルタなし'}
          // defaultValue={filter.bookingMenuId}
          value={filter.bookingMenuId}
          onChange={(e) => {
            onFilterChange({
              ...filter,
              bookingMenuId: e.target.value,
            });
          }}
        >
          {bookingMenus.map((menu) => (
            <option key={menu.id} value={menu.id}>
              {menu.name}
            </option>
          ))}
        </Select>
      </Stack>
      {/* <Button variant={'blue-fill'} onClick={() => openAddDialog(filter)}>
          現在の検索条件を保存
        </Button> */}
    </Stack>
  );
};

const savedFilterToComponent = (bookingMenus: BookingMenu[]) => {
  return (filter: BookingFilter) => {
    const bookingMenu = bookingMenus.find((b) => b.id === filter.bookingMenuId);
    return (
      <VStack w={'full'} p={'16px'} bgColor={'gray.50'} color={'gray.600'}>
        <HStack w={'full'}>
          <Text>予約日指定：</Text>
          <Text>
            {filter.displayDate?.start || '未指定'} ~{' '}
            {filter.displayDate?.end || '未指定'}
          </Text>
        </HStack>
        <HStack w={'full'}>
          <Text>予約メニュー：</Text>
          <Text>{bookingMenu?.name || '未指定'}</Text>
        </HStack>
      </VStack>
    );
  };
};
